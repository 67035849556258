<template>
  <div id="home">
<nav-bar class="nav-bar"><div slot="center">奇安信生活服务</div></nav-bar>    

  </div>
</template>
<script>
import NavBar from 'common/navbar/NavBar'
  // import {authorize, getHomeData} from "network/home";
  import {Grid,GridItem} from "vant"
  import originAxios from 'axios'
export default {
    name: "Home",
    components: {
          NavBar,
          [Grid.name]:Grid,
          [GridItem.name]:GridItem
    },
    created () {

    }


}
</script>

<style scoped>
#grid >>> .van-grid-item__content--center{
 border-radius:5px;
  margin-top: 16px;
  }
  #grid img{
      width: 45px;
  }
   #grid p{
      margin-top: 10px;
      font-size: 15px
  }
  #home{
    margin-bottom: 50px
  }

</style>
